import { omitBy, isNil } from 'lodash/fp'
import api from '@/services/api'

const RESOURCE_URI = '/report'

const fetchOperationsReport = ({
  checkoutId,
  types,
  subTypes,
  paymentTypes,
  date
} = {}) => {
  const query = new URLSearchParams({
    types,
    subTypes,
    paymentTypes,
    date
  }).toString()

  return api.get([`${RESOURCE_URI}/${checkoutId}/operations`, query].join('?'))
}

const fetchCheckoutReport = (checkoutId) =>
  api.get(`${RESOURCE_URI}/${checkoutId}/total`)

const fetchRentsReport = ({
  storeId,
  fromDate,
  toDate,
  page,
  limit = 100
} = {}) => {
  const query = new URLSearchParams(
    omitBy(isNil, { storeId, fromDate, toDate, page, limit })
  ).toString()

  return api.get([`${RESOURCE_URI}/rent`, query].join('?'))
}

const fetchProductPaymentsReport = ({ storeId, fromDate, toDate } = {}) => {
  const query = new URLSearchParams(
    omitBy(isNil, { storeId, fromDate, toDate })
  ).toString()

  return api.get([`${RESOURCE_URI}/product`, query].join('?'))
}

const fetchPaymentMethodsReport = ({ storeId, fromDate, toDate } = {}) => {
  const query = new URLSearchParams(
    omitBy(isNil, { storeId, fromDate, toDate })
  ).toString()

  return api.get([`${RESOURCE_URI}/paymentByMethods`, query].join('?'))
}

const fetchRentsPerToyReport = ({ storeId, fromDate, toDate } = {}) => {
  const query = new URLSearchParams(
    omitBy(isNil, { storeId, fromDate, toDate })
  ).toString()

  return api.get([`${RESOURCE_URI}/toyRents`, query].join('?'))
}

const fetchRentsPerUserReport = ({ storeId, fromDate, toDate } = {}) => {
  const query = new URLSearchParams(
    omitBy(isNil, { storeId, fromDate, toDate })
  ).toString()

  return api.get([`${RESOURCE_URI}/rentsByUser`, query].join('?'))
}

const fetchPaymentsReport = ({ storeId, fromDate, toDate } = {}) => {
  const query = new URLSearchParams(
    omitBy(isNil, { storeId, fromDate, toDate })
  ).toString()

  return api.get([`${RESOURCE_URI}/payments`, query].join('?'))
}

export default {
  fetchOperationsReport,
  fetchCheckoutReport,
  fetchRentsReport,
  fetchProductPaymentsReport,
  fetchRentsPerToyReport,
  fetchRentsPerUserReport,
  fetchPaymentMethodsReport,
  fetchPaymentsReport
}
