<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button size="sm" variant="primary" :to="{ name: 'ToyRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Brinquedo
      </b-button>
    </b-button-toolbar>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle" /> {{ error }}
    </b-alert>

    <div v-for="(toys, storeName) in groupedToys" :key="storeName" class="mb-2">
      <b-card no-body>
        <b-card-header
          class="d-flex justify-content-between align-items-center"
          style="cursor: pointer"
          @click="toggleStore(storeName)"
        >
          <span>{{ storeName }}</span>
          <span
            :class="{
              'fas fa-chevron-down': !collapsedStores[storeName],
              'fas fa-chevron-right': collapsedStores[storeName]
            }"
          ></span>
        </b-card-header>

        <b-collapse
          :id="`collapse-${storeName}`"
          v-model="collapsedStores[storeName]"
        >
          <b-table
            :fields="fields"
            :items="toys"
            striped
            hover
            show-empty
            :busy="isBusy"
            class="mt-2"
          >
            <div slot="empty" class="text-center">
              Nenhum brinquedo cadastrado
            </div>
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="getColumnStyle(field.key)"
              />
            </template>
            <template #cell(image)="{ value: image, item }">
              <img :src="image" :alt="item.name" class="img-fluid" />
            </template>
            <template #cell(store)="{ value: store }">
              {{ store.name }}
            </template>
            <template #cell(status)="{ value: status }">
              <Status :value="status" />
            </template>
            <template #cell(actions)="{ item }">
              <BaseListActions
                :item="item"
                @edit="onEdit"
                @remove="onRemove"
                @updateStatus="onUpdateStatus"
              />
            </template>
          </b-table>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import services from '@/services'
import Status from '@/components/Status'
import BaseListActions from '@/components/BaseListActions'
import withStatusAction from '@/mixins/withStatusAction'
import { parseHTTPError } from '@/helpers/errors'
import { hasActiveRent } from '@/helpers/rents'

export default {
  name: 'ToysList',
  components: {
    Status,
    BaseListActions
  },
  mixins: [withStatusAction(services.toys.updateToy)],
  data() {
    return {
      isBusy: false,
      error: null,
      allToys: [],
      collapsedStores: {}
    }
  },
  computed: {
    fields: () => [
      {
        key: 'image',
        label: 'Imagem'
      },
      {
        key: 'code',
        label: 'Código'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'store',
        label: 'Loja'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ],
    groupedToys() {
      const groups = {}
      for (const toy of this.allToys) {
        const storeName = toy.store ? toy.store.name : 'Sem Loja'
        if (!groups[storeName]) {
          groups[storeName] = []
        }
        groups[storeName].push(toy)
      }

      for (const storeName in groups) {
        groups[storeName].sort((a, b) => {
          if (a.status === 'active' && b.status !== 'active') return -1
          if (a.status !== 'active' && b.status === 'active') return 1
          return 0
        })
        if (this.collapsedStores[storeName] === undefined) {
          this.$set(this.collapsedStores, storeName, false)
        }
      }

      return groups
    }
  },
  async mounted() {
    await this.provider()
  },
  methods: {
    async provider() {
      this.isBusy = true
      try {
        const toys = await services.toys.fetchAllToys()
        this.allToys = toys
        return toys
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    toggleStore(storeName) {
      this.collapsedStores[storeName] = !this.collapsedStores[storeName]
    },
    onEdit(toy) {
      const { id } = toy
      this.$router.push({ name: 'ToyRegister', params: { id } })
    },
    async onRemove(toy) {
      const message = `Deseja realmente remover o brinquedo ${toy.name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)
      if (result) {
        await services.toys.removeToy(toy.id)
        // Após remover, atualiza a lista
        this.provider()
        this.$root.$toast.success('Brinquedo removido com sucesso')
      }
    },
    async onUpdateStatus(toy) {
      const requestToyRent = await hasActiveRent(toy.id)
      if (await requestToyRent) {
        this.$root.$toast.error(
          'Finalize os alugueis ativos com esse brinquedo!'
        )
      } else {
        await this.toggleStatus(toy)
        // Atualiza a lista após mudança de status
        this.provider()
      }
    },
    getColumnStyle(field) {
      switch (field) {
        case 'actions':
          return { width: '1%' }
        case 'image':
          return { width: '15%' }
        default:
          return ''
      }
    }
  }
}
</script>
