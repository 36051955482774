<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button variant="primary" size="sm" :to="{ name: 'PeriodRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Período
      </b-button>
    </b-button-toolbar>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle mr-1" /> {{ error }}
    </b-alert>

    <!-- Aqui está a lógica para agrupar por loja e exibir cada grupo em um cartão (b-card) -->
    <div
      v-for="(periods, storeName) in groupedPeriods"
      :key="storeName"
      class="mb-2"
    >
      <b-card no-body>
        <!-- Cabeçalho do cartão que, ao ser clicado, expande/colapsa a lista -->
        <b-card-header
          class="d-flex justify-content-between align-items-center"
          style="cursor: pointer"
          @click="toggleStore(storeName)"
        >
          <span>{{ storeName }}</span>
          <span
            :class="{
              'fas fa-chevron-down': !collapsedStores[storeName],
              'fas fa-chevron-right': collapsedStores[storeName]
            }"
          ></span>
        </b-card-header>

        <!-- Conteúdo colapsável -->
        <b-collapse
          :id="`collapse-${storeName}`"
          v-model="collapsedStores[storeName]"
        >
          <b-table
            ref="periodsList"
            :fields="fields"
            :items="periods"
            striped
            hover
            show-empty
            :busy="isBusy"
            class="mt-2"
          >
            <div slot="empty" class="text-center">
              Nenhum período cadastrado
            </div>
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="getColumnStyle(field.key)"
              />
            </template>

            <template #cell(time)="{ value: time }">
              {{ time }} minutos
            </template>

            <template #cell(price)="{ value: price }">
              R$ {{ price.toFixed(2) }}
            </template>

            <template #cell(store)="{ value: store }">
              {{ store.name }}
            </template>

            <template #cell(status)="{ value: status }">
              <Status :value="status" />
            </template>

            <template #cell(actions)="{ item }">
              <BaseListActions
                :item="item"
                @edit="onEdit"
                @remove="onRemove"
                @updateStatus="onUpdateStatus"
              />
            </template>
          </b-table>
        </b-collapse>
      </b-card>
    </div>

    <!-- Modal para exibir mensagem de períodos com aluguéis ativos -->
    <b-modal
      id="active-rentals-modal"
      ref="activeRentalsModal"
      title="Aluguéis Ativos Encontrados"
      size="lg"
      hide-footer
      centered
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-center mb-3" v-html="modalMessage"></div>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="closeModal">Entendi</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import services from '@/services'
import Status from '@/components/Status'
import BaseListActions from '@/components/BaseListActions'
import withStatusAction from '@/mixins/withStatusAction'
import { parseHTTPError } from '@/helpers/errors'
import { filterActiveRentalsByPeriod } from '@/helpers/rents'

export default {
  name: 'PeriodsList',
  components: {
    Status,
    BaseListActions
  },
  mixins: [withStatusAction(services.periods.updatePeriod)],
  data() {
    return {
      isBusy: false,
      error: null,
      modalMessage: '',
      allPeriods: [],
      collapsedStores: {}
    }
  },
  computed: {
    fields: () => [
      { key: 'time', label: 'Tempo' },
      { key: 'price', label: 'Preço' },
      { key: 'store', label: 'Loja' },
      { key: 'status', label: 'Status' },
      { key: 'actions', label: 'Ações' }
    ],

    groupedPeriods() {
      const groups = {}

      for (const period of this.allPeriods) {
        const storeName = period.store ? period.store.name : 'Sem Loja'
        if (!groups[storeName]) {
          groups[storeName] = []
        }
        groups[storeName].push(period)
      }

      for (const storeName in groups) {
        groups[storeName].sort((a, b) => {
          if (a.status === 'active' && b.status !== 'active') return -1
          if (a.status !== 'active' && b.status === 'active') return 1
          return 0
        })

        if (this.collapsedStores[storeName] === undefined) {
          this.$set(this.collapsedStores, storeName, false)
        }
      }

      return groups
    }
  },
  async mounted() {
    await this.provider()
  },
  methods: {
    async provider() {
      this.isBusy = true
      try {
        const periods = await services.periods.fetchAllPeriods()
        this.allPeriods = periods
        return periods
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    toggleStore(storeName) {
      this.collapsedStores[storeName] = !this.collapsedStores[storeName]
    },
    onEdit(period) {
      const { id } = period
      this.$router.push({ name: 'PeriodRegister', params: { id } })
    },
    async onRemove(period) {
      const requestRentsByPeriods = await filterActiveRentalsByPeriod(period.id)

      if (requestRentsByPeriods.length > 0) {
        const toyNames = [
          ...new Set(requestRentsByPeriods.map((rent) => rent.toyName))
        ]

        const toyMessages = toyNames.map((name) => `${name}`).join('<br>')

        this.modalMessage = `
          <p>
            Existem aluguéis ativos para este período nos seguintes brinquedos:
            <br><br>
            ${toyMessages}
            <br><br>
            Não é possível remover este período enquanto houver aluguéis ativos.
          </p>
        `
        this.$refs.activeRentalsModal.show()
      } else {
        const message = `Deseja realmente remover o período ${period.time}?`
        const result = await this.$bvModal.msgBoxConfirm(message, {
          title: 'Confirmar Remoção',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          centered: true
        })

        if (result) {
          await services.periods.removePeriod(period.id)
          this.provider()
          this.$root.$toast.success('Período removido com sucesso')
        }
      }
    },
    closeModal() {
      this.$refs.activeRentalsModal.hide()
    },
    async onUpdateStatus(period) {
      await this.toggleStatus(period)
      this.provider()
    },
    getColumnStyle(field) {
      return { width: field === 'actions' ? '1%' : '' }
    }
  }
}
</script>
