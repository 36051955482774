import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const MAX_RETRY_ATTEMPTS = 5

const state = {
  shoppingId: null,
  rents: [],
  eventSource: null,
  retryAttempts: 0
}

const mutations = {
  SET_SHOPPING_ID(state, shoppingId) {
    state.shoppingId = shoppingId
  },
  SET_RENTS(state, rents) {
    state.rents = rents
  },
  SET_EVENT_SOURCE(state, eventSource) {
    state.eventSource = eventSource
  },
  CLOSE_EVENT_SOURCE(state) {
    if (state.eventSource) {
      state.eventSource.close()
      state.eventSource = null
    }
  },
  SET_RETRY_ATTEMPTS(state, attempts) {
    state.retryAttempts = attempts
  }
}

const actions = {
  startSSE({ commit, state, dispatch }) {
    const { shoppingId } = state
    if (
      state.eventSource &&
      [EventSource.CONNECTING, EventSource.OPEN].includes(
        state.eventSource.readyState
      )
    ) {
      console.log(
        'Conexão SSE já está ativa ou em processo de conexão. Não será reiniciada.'
      )
      return
    }
    if (!shoppingId) {
      console.error('shoppingId não definido.')
      return
    }
    console.log('Iniciando conexão SSE para shoppingId:', shoppingId)

    commit('CLOSE_EVENT_SOURCE')

    const apiUrl = process.env.VUE_APP_API_URL
    const url = `${apiUrl}/sse/${encodeURIComponent(shoppingId)}`

    const eventSource = new EventSource(url)

    eventSource.onmessage = (event) => {
      try {
        const rentsData = JSON.parse(event.data)
        commit('SET_RENTS', rentsData)

        commit('SET_RETRY_ATTEMPTS', 0)
      } catch (err) {
        console.error('Erro ao parsear dados SSE:', err)
      }
    }

    eventSource.onerror = (error) => {
      console.error('Erro na conexão SSE:', error)
      commit('CLOSE_EVENT_SOURCE')

      const currentAttempts = state.retryAttempts + 1
      commit('SET_RETRY_ATTEMPTS', currentAttempts)

      if (currentAttempts <= MAX_RETRY_ATTEMPTS) {
        console.warn(
          `Tentando reconectar... (Tentativa ${currentAttempts} de ${MAX_RETRY_ATTEMPTS})`
        )
        dispatch('startSSE')
      } else {
        console.error(
          `Falha na reconexão após ${MAX_RETRY_ATTEMPTS} tentativas. Recarregando a página.`
        )
        setInterval(() => {
          window.location.reload()
        }, 2000)
      }
    }

    commit('SET_EVENT_SOURCE', eventSource)
  },

  stopSSE({ commit }) {
    commit('CLOSE_EVENT_SOURCE')
    commit('SET_RETRY_ATTEMPTS', 0)
  },

  setShoppingAndStart({ commit, dispatch }, shoppingId) {
    commit('SET_SHOPPING_ID', shoppingId)
    commit('SET_RETRY_ATTEMPTS', 0)
    dispatch('startSSE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
