<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <!-- <CheckoutFilter v-model="filter" /> -->
    <CheckoutDetails
      :checkout="currentCheckout"
      :checkouts="filteredCheckouts"
      @refresh="loadCheckouts"
    >
      <template #actions="{ onOpenCheckout }">
        <b-button-toolbar v-if="!hasOpenCheckout" key-nav class="mb-3">
          <b-button variant="primary" size="sm" @click="onOpenCheckout">
            <span class="fas fa-plus mr-1" /> Abrir Novo Caixa
          </b-button>
        </b-button-toolbar>
      </template>
    </CheckoutDetails>
  </Layout>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { formatDate } from '@/helpers/date'
import Layout from '@/views/Layout'
import CheckoutDetails from '@/components/Checkouts/CheckoutDetails'

export default {
  name: 'StoresIndexView',
  components: {
    Layout,
    CheckoutDetails
  },
  data() {
    return {
      filter: {
        storeId: this.currentStoreId,
        date: formatDate(new Date())
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId']),
    ...mapState('checkout', ['currentCheckout', 'checkouts']),
    hasOpenCheckout() {
      return this.currentCheckout && !this.currentCheckout.closedAt
    },
    filteredCheckouts() {
      return {
        ...this.checkouts,
        data: this.currentCheckout ? [this.currentCheckout] : []
      }
    },
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Caixas',
        to: { name: 'CheckoutsIndex' },
        active: true
      }
    ]
  },
  watch: {
    filter: {
      handler(value) {
        this.fetchCheckouts(value)
      },
      deep: true
    }
  },
  created() {
    console.log(this.currentCheckout)
    if (!this.currentCheckout) {
      this.loadCheckouts()
    }
  },
  methods: {
    ...mapActions('checkout', ['fetchCheckouts']),
    async loadCheckouts() {
      await this.fetchCheckouts()
    }
  }
}
</script>
