import { required } from 'vuelidate/lib/validators'
import { calculateEndDate } from './helpers/rents'
import { isEmpty } from 'lodash/fp'

export const STATUS = {
  ACTIVE: {
    value: 'active',
    text: 'Ativo'
  },
  INACTIVE: {
    value: 'inactive',
    text: 'Inativo'
  }
}

export const RENT_STATUS = {
  ...STATUS,
  PAUSED: {
    value: 'paused',
    text: 'Pausado'
  },
  CANCELLED: {
    value: 'canceled',
    text: 'Cancelado'
  },
  ENDED: {
    value: 'ended',
    text: 'Finalizado'
  },
  CLOSED: {
    value: 'closed',
    text: 'Fechado'
  }
}

export const CHECKOUT_STATUS = {
  ...STATUS,
  OPEN: {
    value: 'open',
    text: 'Aberto'
  },
  CLOSED: {
    value: 'closed',
    text: 'Fechado'
  }
}

export const ROLES = {
  ADMIN: {
    value: 'admin',
    text: 'Admin'
  },
  SHOPPING: {
    value: 'shopping',
    text: 'Shopping'
  },
  EMPLOYER: {
    value: 'employer',
    text: 'Usuário'
  }
}

export const FILTERS_IDS = {
  ALL: 'all',
  AVAILABLE: 'available',
  RENT: 'rent',
  PAUSED: 'paused',
  DELAYED: 'delayed',
  ACTIVE: 'active'
}

export const FILTERS = {
  [FILTERS_IDS.ALL]: (toy) => toy.status === 'active',
  [FILTERS_IDS.AVAILABLE]: (toy) =>
    isEmpty(toy.rent) ||
    (toy.rent.status === RENT_STATUS.CLOSED.value && toy.status === 'active') ||
    (toy.rent.status === RENT_STATUS.CANCELLED.value &&
      toy.status === 'active'),
  [FILTERS_IDS.RENT]: (toy) =>
    (toy.rent?.status === RENT_STATUS.ACTIVE.value &&
      toy.status === 'active') ||
    (toy.rent?.status === RENT_STATUS.PAUSED.value && toy.status === 'active'),
  [FILTERS_IDS.PAUSED]: (toy) =>
    toy.rent?.status === RENT_STATUS.PAUSED.value && toy.status === 'active',
  [FILTERS_IDS.DELAYED]: (toy) => {
    const endDate = calculateEndDate(toy.rent)
    const now = new Date()
    const isActive =
      toy.rent?.status === 'active' || toy.rent?.status === 'paused'
    const isOverdue = endDate < now

    return isActive && isOverdue
  },
  [FILTERS_IDS.ACTIVE]: (toy) => toy.status === 'active'
}

export const RENT_ACTIONS = {
  CANCEL: 'cancel',
  CLOSE: 'close',
  PAUSE: 'pause',
  START: 'start',
  TRANSFER: 'transfer',
  ADD_EXTRA_TIME: 'addExtraTime',
  REOPEN_LAST_RENT: 'reopenLastRent',
  CHANGE_PERIOD: 'changePeriod',
  ADD_PRODUCT: 'addProduct',
  REMOVE_PRODUCT: 'removeProduct'
}

export const PRODUCT_ACTIONS = {
  BUY: 'buy'
}

export const RENT_ACTIONS_TITLES = {
  [RENT_ACTIONS.CANCEL]: 'Cancelar',
  [RENT_ACTIONS.CLOSE]: 'Encerrar',
  [RENT_ACTIONS.PAUSE]: 'Pausar',
  [RENT_ACTIONS.START]: 'Iniciar',
  [RENT_ACTIONS.TRANSFER]: 'Transferir',
  [RENT_ACTIONS.ADD_EXTRA_TIME]: 'Adicionar Tempo Extra -',
  [RENT_ACTIONS.CHANGE_PERIOD]: 'Alterar Período -'
}

export const RENT_ACTIONS_VALIDATIONS = {
  [RENT_ACTIONS.CANCEL]: {
    form: { reason: { required } }
  },
  [RENT_ACTIONS.CLOSE]: {
    form: { reason: { required } }
  },
  [RENT_ACTIONS.PAUSE]: {
    form: { reason: { required } }
  },
  [RENT_ACTIONS.START]: {
    form: { reason: { required } }
  },
  [RENT_ACTIONS.TRANSFER]: {
    form: { reason: { required } }
  },
  [RENT_ACTIONS.ADD_EXTRA_TIME]: {
    form: { time: { required } }
  }
}

export const PAYMENT_METHODS_IDS = {
  CASH: 'cash',
  DEBIT_CARD: 'debitCard',
  CREDIT_CARD: 'creditCard',
  PIX: 'pix'
}

export const PAYMENT_METHODS = {
  [PAYMENT_METHODS_IDS.CASH]: {
    type: PAYMENT_METHODS_IDS.CASH,
    name: 'Dinheiro',
    icon: 'fas fa-wallet'
  },
  [PAYMENT_METHODS_IDS.DEBIT_CARD]: {
    type: PAYMENT_METHODS_IDS.DEBIT_CARD,
    name: 'Débito',
    icon: 'fas fa-credit-card'
  },
  [PAYMENT_METHODS_IDS.CREDIT_CARD]: {
    type: PAYMENT_METHODS_IDS.CREDIT_CARD,
    name: 'Crédito',
    icon: 'fas fa-credit-card'
  },
  [PAYMENT_METHODS_IDS.PIX]: {
    type: PAYMENT_METHODS_IDS.PIX,
    name: 'PIX',
    icon: 'fas fa-university'
  }
}

export const PAYMENT_SUBTYPES = {
  RENT: 'rent',
  PRODUCT: 'product'
}

export const RENT_ACTIONS_INITIAL_STATES = {
  [RENT_ACTIONS.CANCEL]: () => ({
    reason: ''
  }),
  [RENT_ACTIONS.CLOSE]: () => ({
    payments: Object.values(PAYMENT_METHODS).map((paymentMethod) => ({
      ...paymentMethod,
      amount: 0
    })),
    reason: ''
  }),
  [RENT_ACTIONS.PAUSE]: () => ({
    reason: ''
  }),
  [RENT_ACTIONS.START]: () => ({}),
  [RENT_ACTIONS.TRANSFER]: () => ({
    reason: ''
  }),
  [RENT_ACTIONS.CHANGE_PERIOD]: () => ({
    periodId: null
  })
}

export const CHECKOUT_VALUES = [
  {
    amount: 0.05,
    quantity: null
  },
  {
    amount: 0.1,
    quantity: null
  },
  {
    amount: 0.25,
    quantity: null
  },
  {
    amount: 0.5,
    quantity: null
  },
  {
    amount: 1,
    quantity: null
  },
  {
    amount: 2,
    quantity: null
  },
  {
    amount: 5,
    quantity: null
  },
  {
    amount: 10,
    quantity: null
  },
  {
    amount: 20,
    quantity: null
  },
  {
    amount: 50,
    quantity: null
  },
  {
    amount: 100,
    quantity: null
  },
  {
    amount: 200,
    quantity: null
  }
]

export const CHECKOUT_OPERATIONS = {
  OPEN: 'open',
  CLOSE: 'close',
  OPERATIONS: 'operations'
}

export const OPERATION_TYPES = {
  REVENUE: 'revenue',
  EXPENSE: 'expense'
}

export const OPERATION_TYPES_LABELS = {
  [OPERATION_TYPES.REVENUE]: 'Entrada',
  [OPERATION_TYPES.EXPENSE]: 'Saída'
}

export const OPERATION_NAMES_LABEL = {
  rent: 'Aluguel',
  product: 'Produto',
  payment: 'Movimentação Manual'
}

export const USER_ROLES = {
  ROOT: 'root',
  ADMIN: 'admin',
  SHOPPING: 'shopping',
  EMPLOYER: 'employer'
}

export const HTTP_ERRORS = {
  404: 'Recurso não encontrado',
  500: 'Erro interno do servidor',
  403: 'Você não tem permissão para acessar este recurso',
  401: 'Sua autenticação expirou'
}

export const HTTP_MULTIPART_CONFIG = {
  header: {
    'Content-Type': 'multipart/form-data'
  }
}

export const HOME_ITEM_TYPES = {
  TOY: 'toy',
  PRODUCT: 'product'
}

export const PRODUCT_DEFAULT_IMAGE =
  'https://i.ibb.co/N78kF1P/logo-Cropped-min.png'

export const EXTRA_TIME_TYPES = {
  PAUSE: 'pause'
}

export const VERSION_APP = {
  VERSION: '1801-04'
}
